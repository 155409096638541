.input_container {
    position: relative;
    
    .label {
        position: absolute;
        opacity: 0;
    }

    .input {
        width: 100%;
        height: 45px;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
        border-radius: 50px;
        position: relative;
        padding: 0 20px 0 20px;
       
    }

    .input:disabled {
        background: #F5F5F5;
    }
}