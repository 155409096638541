.text_area_input {
    position: relative;
    width: 100%;
    margin-top: 16px;
    // display: flex;

    .title {
        font-weight: 600;
        font-size: 16px;
    }

    .label {
        position: absolute;
        opacity: 0;
    }

    .input {
        width: 100%;
        height: 150px;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
        border-radius: 30px;
        position: relative;
        padding: 20px;
        resize: vertical;
    }
    
    .redStar {    
        color: red;
    }
}