.tableHeader {
    position: sticky;
    top: 6em;
    background-color: white;
    z-index: 1; 
}

.tableStyle {
    text-align: center;
    vertical-align: middle !important;
}

tr.alertStyle {
    background-color: #ff00005e !important;
  }

.dropDownStyle {
    background-color: inherit !important;
    border-color: grey !important;
    height: 34px !important;
    border-radius: 15px !important;
    padding: 0 1em !important;
    color: black !important;
}

.dropDownStyle:hover {
    border-color: grey !important;
    background-color: rgb(231, 231, 231) !important;
    color: black !important;
}

.dropDownStyle:focus {
    box-shadow: none !important;
}

.startButton {
    background-color: #93c020;
    height: 34px;
    border-radius: 15px;
    border-style: solid;
    border-color: #93c020;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
.startButton:hover {
    border-style: solid;
    border-color: #93c020;
    background-color: white;
    color: #93c020;
}
  
.startButton:disabled {
   cursor: wait;
   border-style: solid;
   border-color: #93c020;
   background-color: white;
   color: #93c020;
}
  
.stopButton {
    background-color: red;
    height: 34px;
    border-radius: 15px;
    border-style: solid;
    border-color: red;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
.stopButton:hover {
    border-style: solid;
    border-color: red;
    background-color: white;
    color: red
}

.languageStyle {
    margin: 1em 0;
}

.emptyExcluded {
    margin: 0;
    line-height: 1em;
    font-size: 1.5em;
}