.cancelButton {
    height: 34px;
    background-color: white;
    border: 2px var(--color-1);
    border-style: solid;
    border-radius: 15px;
    padding: 0 1em;
    color: var(--color-1);
    flex-wrap: wrap;
    min-width: 100px;
}
  
.cancelButton:hover {
    background-color: #93c020;
    color: white;
    border-color: #93c020;
}

.startButton {
    background-color: #93c020;
    height: 34px;
    border-radius: 15px;
    border-style: solid;
    border-color: #93c020;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
  .startButton:hover {
    border-style: solid;
    border-color: #93c020;
    background-color: white;
    color: #93c020;
}
  
  .startButton:disabled {
   cursor: wait;
   border-style: solid;
   border-color: #93c020;
   background-color: white;
   color: #93c020;
}
  
.stopButton {
    background-color: red;
    height: 34px;
    border-radius: 15px;
    border-style: solid;
    border-color: red;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
  .stopButton:hover {
    border-style: solid;
    border-color: red;
    background-color: white;
    color: red
}
  
  .stopButton:disabled {
    cursor: wait;
    border-color: red;
    background-color: white;
    color: red
}