.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.redStar {    
    color: red;
}

.infoAlignment {
    display: flex;
    justify-content: space-between;
    align-items: center;
}