.layout {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.cardAlignment {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.cards {
    display: flex;
    width: 16rem;
    justify-content: center;
}

.cardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.inactiveCardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: rgb(246, 246, 246);
}

.cardButton {
    background-color: var(--color-1);
    height: 34px;
    border-radius: 15px;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
.cardButton:hover {
    background-color: #93c020;
}

.cancelButton {
    height: 34px;
    background-color: white;
    border: 2px var(--color-1);
    border-style: solid;
    border-radius: 15px;
    padding: 0 1em;
    color: var(--color-1);
    flex-wrap: wrap;
    min-width: 100px;
}
  
.cancelButton:hover {
    background-color: #93c020;
    color: white;
    border-color: #93c020;
}

.productCardAlignment {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.startButton {
    background-color: #93c020;
    height: 34px;
    border-radius: 15px;
    border-style: solid;
    border-color: #93c020;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
.startButton:hover {
    border-style: solid;
    border-color: #93c020;
    background-color: white;
    color: #93c020;
}
  
.startButton:disabled {
   cursor: wait;
   border-style: solid;
   border-color: #93c020;
   background-color: white;
   color: #93c020;
}
  
.stopButton {
    background-color: red;
    height: 34px;
    border-radius: 15px;
    border-style: solid;
    border-color: red;
    padding: 0 1em;
    color: white;
    flex-wrap: wrap;
    min-width: 100px;
}
  
.stopButton:hover {
    border-style: solid;
    border-color: red;
    background-color: white;
    color: red
}
  
.stopButton:disabled {
    cursor: wait;
    border-color: red;
    background-color: white;
    color: red
}

.redDot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
}

.greenDot {
    height: 10px;
    width: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    
}

.greyLine {
    border: none;
    border-top: 1px solid grey;
    color: grey;
    background-color: grey;
    height: 1px;
    width: 100%;
}
