.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.nav-bar {
  background-color: white;
  height: 6em;
  padding: 1em 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: 8em 5vw;
}

.nav-bar-item {
  font-family: var(--font-1);
  font-size: 1.1em;
  padding: 1em 3em;
  color: black;
  border-left: solid 1px var(--color-3);
}

.nav-bar-item:first-child {
  border-left: none;
}
