.input_container {
    position: relative;
    .label {
        position: absolute;
        opacity: 0;
    }

    .input {
        width: 100%;
        height: 45px;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
        border-radius: 50px;
        position: relative;
        padding: 0 20px 0 20px;
    }

    .icon {
        width: 57px;
        height: 60px;
        left: 0px;
        top: 0px;
        position: absolute;
        z-index: 1;
        background: #838383;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    margin-top: 16px;
}
.hover_info {
    margin-top: 16px;
}

.redStar {
    color: red;
}