@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
/* this is for the font from the Figma design */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,200,0,0");

:root {
  --color-1: #2e5744;
  --color-2: #757575;
  --color-3: #e3cfc2;
  --color-4: #23314b;
  --color-5: #838383;

  --font-1: "Questrial", sans-serif;
  --font-2: "Montserrat", sans-serif;
  --font-code: "Source Code Pro", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1,
h2,
p,
a,
input,
td,
textarea,
span {
  /* font-family: var(--font-1); */
  font-family: "Ubuntu", sans-serif;
}

h1 {
  font-size: 3em;
  font-weight: 100;
}

h2 {
  font-size: 2em;
  font-weight: 100;
}

/* h1,
h2 {
  text-transform: capitalize;
} */

h3,
th {
  font-size: 1em;
  font-weight: 600;
  /* font-family: var(--font-2); */
  font-family: "Ubuntu", sans-serif;
  color: var(--color-4);
  /* text-transform: uppercase; */
}

label {
  padding: 0;
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40em;
}

label h3 {
  width: 10em;
}

label p {
  width: 10em;
  padding-right: 1em;
  text-align: right;
}

p,
a,
input,
select,
td,
textarea {
  font-size: 1em;
}

input,
select {
  padding: 0.5em;
  width: 20em;
  border-radius: 5px;
  border-width: 1px;
  /* margin: 1em 0; */
  width: 100%;
}

select:invalid {
  color: gray;
}

textarea {
  padding: 0.5em;
  resize: none;
}

label textarea {
  width: 20em;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: var(--color-1);
}

button {
  background-color: var(--color-1);
  height: 3em;
  border-radius: 9999px;
  margin: 1em 0;
  padding: 0 2em;
  border: none;
  font-size: 1em;
  /* font-family: var(--font-2); */
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  color: white;
  cursor: pointer;
  text-transform: capitalize;
}

button:hover {
  background-color: var(--color-3);
}

button:disabled {
  background-color: var(--color-2);
}

button,
a {
  transition: all 0.2s;
}

.code {
  /* font-family: var(--font-code); */
  font-family: "Ubuntu", sans-serif;
  color: white;
  background-color: black;
}

table {
  width: 100%;
  text-align: left;
}

th,
td {
  padding: 1em;
}

.adsTitle {
  text-align: center;
}

::placeholder {
  font-family: "Ubuntu", sans-serif;
  color: var(--color-5);
}

/* popup styling */

.extraInfo {
  color: var(--color-5);
}

.extraInfo > .popup {
  display: none;
}

.extraInfo:hover > .popup {
  display: block;
}

.popup {
  background: var(--color-3);
  border-radius: 5px;
  width: auto;
  height: auto;
  color: var(--color-4);
  padding: 5px;
  position: relative;
  display: inline-block;
}

/* icons for the input fields */
* {
  box-sizing: border-box;
}

/* Style the input container */
.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

/* Style the form icons */
.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

/* Style the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.create-ad-button {
  background-color: var(--color-1);
  height: 34px;
  border-radius: 15px;
  padding: 0 1em;
  color: white;
}

.create-ad-button:hover {
  background-color: #93c020;
}

.start-stop-ad {
  background-color: var(--color-1);
  height: 34px;
  border-radius: 15px;
  padding: 0 1em;
  color: white;
  flex-wrap: wrap;
  min-width: 100px;
}

.start-stop-ad:hover {
  background-color: #93c020;
}

.custom-round-button {
  background-color: var(--color-1);
  height: 34px;
  border-radius: 15px;
  padding: 0 1em;
  color: white;
  flex-wrap: wrap;
  min-width: 100px;
}

.custom-round-button:hover {
  background-color: #93c020;
}
