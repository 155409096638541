.my_container {
  display: flex;
  flex-direction: column;
  padding: 0 7%;

  .my_row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .form_input_info {
      width: calc(50% - 12px);
      min-width: 240px;
    }
  }
}

.error_msg {
  height: 28px;
  line-height: 28px;
  color: red;
  text-align: right;
  margin-bottom: 0;
  margin-top: 5px;
}

.cardButton {
  background-color: var(--color-1);
  height: 34px;
  border-radius: 15px;
  padding: 0 1em;
  color: white;
  flex-wrap: wrap;
  min-width: 100px;
}

.cardButton:hover {
  background-color: #93c020;
}
.cardButton:disabled {
  cursor: wait;
  background-color: gray;
}

.cancelButton {
  height: 34px;
  background-color: white;
  border: 2px var(--color-1);
  border-style: solid;
  border-radius: 15px;
  padding: 0 1em;
  color: var(--color-1);
  flex-wrap: wrap;
  min-width: 100px;
}

.cancelButton:hover {
    background-color: #93c020;
    color: white;
    border-color: #93c020;
}

.subtitles {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}



// everything below this point is for the locations field
.input_container {
  position: relative;

  .label {
      position: absolute;
      opacity: 0;
  }

  .input {
      width: 100%;
      height: 60px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
      border-radius: 12px;
      position: relative;
      padding: 0 20px 0 75px;

      .dropdown {
          width: 100%;
          position: absolute; 
          top: 60px;
      }
  
      .option {
          padding: 10px; 
          background: white;
          border-bottom: 1px solid gray
      }
  }

  .icon {
      width: 57px;
      height: 60px;
      left: 0px;
      top: 0px;
      position: absolute;
      z-index: 1;
      background: #838383;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.hover_info {
  margin-top: 16px;
}

.tag {
  background-color: #C4C4C4;
  width: fit-content;
  padding: 5px;
  border-radius: 12px;
  display: inline-flex;
  margin-right: 10px;
  margin-top: 10px;
}

.tag_button {
  background-color: #C4C4C4;
  height: 5px;
  width: 2px;
}

.tag_button:hover {
  background-color: #C4C4C4;
}

.checkbox {
  width: auto;
  margin-left: 1.5em;
}

//toggle switch

.toggle {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px; // reduced height
  margin-left: 1em;
  vertical-align: middle; // align the toggle with the text
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 16px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px; 
  width: 14px; 
  left: 1px;
  bottom: 1px; 
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle input:checked + .slider {
  background-color: #93c020;
}

.toggle input:focus + .slider {
  box-shadow: 0 0 1px #93c020;
}

.toggle input:checked + .slider:before {
  transform: translateX(18px); 
}