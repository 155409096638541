.my_button{
    border-radius: 15px;
    padding: 10px;
    float: right;
    background-color: var(--color-1);
    color: white;
    font-weight: bold;
    height: 37px;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.my_button:hover{
    background-color: #93c020;
    cursor: pointer;
}
