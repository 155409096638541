.hover_wrapper {
  position: relative;
  width: auto;

  .content {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 4px;
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      color: grey;
    }
    &:hover {
        ~.hover_text {
            opacity: 1;
            transition: 0.3s;
        }
    }
  }
  .hover_text {
    background-color: white;
    box-shadow: 0 0 24px rgb(0 28 5 / 16%);
    border: 1px solid darkgrey;
    padding: 8px 12px;
    border-radius: 8px;
    position: absolute;
    user-select: none;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
  }
}
